//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React from 'react';

//Contexts

//Images
import lendleaseLogo from '../Images/Logo_Lendlease.svg';

//CSS
import './Navbar.css';


export default function Navbar() {
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='Navbar-Container'>

      {/* Logo */}
      <div className='Navbar-Logo'>
        <img className='Logo' src={lendleaseLogo} alt='Cloud Logo'></img>
        <h4>LL JSCS Search</h4>
      </div>
      
    </div>
  )
}

