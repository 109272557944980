//================================================================
//  Application: Events App 
//================================================================

//  Product Owner: Scott Wrigley
//  Created 11/04/2022

//================================================================

//Libraries
import React from 'react';
import { Routes, Route } from "react-router-dom";

//Contexts

//Pages
import Search from './Pages/Search/Search.js';
import NotFound from './Pages/NotFound/NotFound';

//Components
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className="App-Container">

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>
       
        <Routes>
          <Route path="/" element={<Search />} />

          <Route path="*" element={<NotFound />}></Route>
        </Routes>

        <Footer></Footer>

      </div>

    </div>
  )

  //------------------------------------------------------
}